body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

a {
  color: #5F8FC1;
  text-decoration: none; }

.MuiDialogTitle-root {
  .MuiTypography-root {
    font-weight: bold;
    text-align: center; } }

.MuiDialogContent-root {
  text-align: center; }
